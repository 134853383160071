<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-8 ml-0">
                <div class="d-flex align-items-center">
                    <button class="btn btn-primary" @click="handleRefresh">
                        <i class="ri-refresh-line"></i>
                    </button>
                </div>
            </div>
        </div>

        <TableListComponent
            :config="configTable"
            :dataList="historyListDocument"
            :isLoading="isLoadingTable"
            :configPage.sync="configPage"
            :visibleAction="false"
            @onChangePage="getHistory()"
        >
            <template #row="{ row }">
                <tr>
                    <td class="text-left">
                        <div class="row-data-medium">
                            <a target="_blank" :href="row.url" v-b-tooltip.hover :title="row?.name ?? ''">{{ row.name }}</a>
                        </div>
                    </td>
                    <td>
                        <div class="">
                            <p class="text-center p-2 rounded" :style="`background-color: ${handleBgStatus(row.status)}; color:#ffff`">
                                {{ $t(row.status) }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <div class="row-data-small">
                            {{ row.file_type }}
                        </div>
                    </td>
                    <td>
                        <div class="row-data-small">
                            {{ formatDateCommon(row.created_at, 'dateTimeJP') }}
                        </div>
                    </td>
                    <td>
                        <div class="row-data-small">{{ row.size ? Number(row.size.toFixed(1)) : '0' }}mb</div>
                    </td>
                </tr>
            </template>
        </TableListComponent>
    </div>
</template>

<script>
import TableListComponent from '@/components/TableList/main.vue';

import { campaignMethods } from '@/state/helpers';
import { formatDateCommon } from '@/utils/format';

export default {
    components: {
        TableListComponent
    },
    props: {
        activeTab: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            isLoadingTable: false,
            historyListDocument: [],
            configTable: {
                headers: [
                    {
                        label: 'ファイル名'
                    },
                    {
                        label: 'ステータス'
                    },
                    {
                        label: '形式'
                    },
                    {
                        label: '時間'
                    },
                    {
                        label: 'サイズ'
                    }
                ]
            },
            configPage: {
                total: 0,
                limit: 10,
                page: 1
            }
        };
    },
    methods: {
        ...campaignMethods,

        getQueryString() {
            let query = [`page=${this.configPage.page}`, `limit=${this.configPage.limit}`, `sorts[id]=desc`];
            return query.join('&');
        },

        handleRefresh() {
            this.configPage.page = 1;
            this.getHistory();
        },

        getHistory() {
            this.historyListDocument = [];
            this.isLoadingTable = true;
            let query = this.getQueryString();
            this.historyDocuments(query).then((data) => {
                if (data.code == 200) {
                    this.historyListDocument = data.data;
                    this.configPage.total = data._metadata.total;
                    this.configPage.limit = data._metadata.limit * 1;
                }
                this.isLoadingTable = false;
            });
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        handleBgStatus(status) {
            switch (status) {
                case 'success':
                    return '#1cbb8c';
                case 'processing':
                    return '#fea217';
                case 'failed':
                    return '#ea4335';
            }
        }
    },
    watch: {
        activeTab: {
            handler: function (tab) {
                if (tab == 1) this.getHistory();
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
