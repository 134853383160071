import i18n from '@/i18n';
import moment from 'moment';
import { formatDateCommon } from '@/utils/format';

const configs = {
    configSelectCampaign: {
        trackBy: 'id',
        label: 'name',
        error: false,
        errorField: '',
        errorMsg: 'キャンペーン名を選択してください。',
        isRemove: false
    },
    configSelectClient: {
        trackBy: 'client_id',
        label: 'client_name',
        error: false,
        errorField: 'エンドクライアント',
        isRemove: false,
        valueSync: '',
        typesValidate: ['requiredSelect']
    },
    configExportSchedule: {
        id: '',
        code: '',
        class: 'modal-custom-tab-basic-cp',
        title: i18n.t('sheduleFile.popUpSchedule'),
        model: false,
        scrollable: true,
        hideFooter: false,
        size: 'xl',
        validate: true,
        dataCPType: [],
        dataDocument: [],
        listIDWorks: []
    },
    configModalCommonCondition: {
        id: '',
        title: '条件設定',
        model: false,
        scrollable: true,
        hideFooter: false,
        size: 'lg',
        class: 'h-60',
        typeDoc: '',
        validate: true
    },
    configModalCommonConditionFMT: {
        id: '',
        title: i18n.t('document.mumberOfMessagesFMT'),
        model: false,
        scrollable: true,
        hideFooter: false,
        size: 'lg',
        class: 'h-80',
        detailBeginForDocument: null,
        validate: true
    },
    configModalCommonPreview: {
        id: '',
        title: '内容',
        model: false,
        scrollable: true,
        hideFooter: false,
        size: 'xl',
        titleBtn: '閉じる'
    },
    configModalCommonConditionOther: {
        id: '',
        title: '内容',
        model: false,
        scrollable: true,
        hideFooter: false,
        size: 'xl',
        validate: true
    },
    configFaq: {
        isLoadingPreviewFAQ: false,
        listData: {},
        listTitle: {},
        id: '',
        title: 'プレビュー',
        model: false,
        scrollable: true,
        hideFooter: false,
        size: '3xl',
        class: 'modal-custom-tab-basic-cp'
    },
    configFieldCycle: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: true,
        errorField: i18n.t('document.deliveryCycle'),
        valueSync: null,
        isRequired: false,
        typesValidate: ['requiredSelect']
    },
    configFieldClosingDate: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false,
        errorField: i18n.t('document.closingDate'),
        valueSync: null,
        isRequired: false,
        typesValidate: ['requiredSelect']
    },
    configFieldReportDate: {
        trackBy: 'id',
        label: 'name',
        error: false,
        isRemove: false,
        errorField: i18n.t('document.reportDate'),
        valueSync: null,
        isRequired: false,
        typesValidate: ['requiredSelect']
    }
};

const cycleFMT = [
    {
        id: 4,
        name: '最終一括'
    },
    {
        id: 1,
        name: '1週間'
    },
    {
        id: 2,
        name: '1ヶ月'
    },
    {
        id: 3,
        name: '隔週'
    }
];
const DayOfWeek = [
    {
        id: 1,
        name: '月曜日'
    },
    {
        id: 2,
        name: '火曜日'
    },
    {
        id: 3,
        name: '水曜日'
    },
    {
        id: 4,
        name: '木曜日'
    },
    {
        id: 5,
        name: '金曜日'
    },
    {
        id: 6,
        name: '土曜日'
    },
    {
        id: 7,
        name: '日曜日'
    }
];

function LISTCondition() {
    return [
        {
            label: i18n.t('document.mumberOfMessagesFMT'),
            data: [
                {
                    id: '22',
                    source: 'fmt_twitter_instagram',
                    name: '【日別応募数報告(Twitter・Instagram集計)】(第●回)キャンペーン名称_yyyymmdd.xlsx',
                    conditions: {
                        campaign_start_date: '',
                        campaign_end_date: '',
                        categories: '',
                        cycle: cycleFMT[0],
                        closing_date: null,
                        report_date: null,
                        skip_the_first_week_closing: false,
                        include_it_one_week_before: false
                    }
                },
                {
                    id: '23',
                    source: 'fmt_web',
                    name: '【日別応募数報告(WEB集計)】(第●回)キャンペーン名称_yyyymmdd.xlsx',
                    conditions: {
                        campaign_start_date: '',
                        campaign_end_date: '',
                        categories: '',
                        cycle: cycleFMT[0],
                        closing_date: null,
                        report_date: null,
                        skip_the_first_week_closing: false,
                        include_it_one_week_before: false
                    }
                },
                {
                    id: '24',
                    source: 'fmt_postcard',
                    name: '【日別応募数報告(〆計・はがき集計あり)】(第● 回)キャンペーン名称_yyyymmdd.xlsx',
                    conditions: {
                        campaign_start_date: '',
                        campaign_end_date: '',
                        categories: '',
                        cycle: cycleFMT[0],
                        closing_date: null,
                        report_date: null,
                        skip_the_first_week_closing: false,
                        include_it_one_week_before: false
                    }
                },
                {
                    id: '25',
                    source: 'fmt_web_postcard',
                    name: '【日別応募数報告(ハガキ・WEB×ハガキ集計)】(第●回)キャンペーン名称_yyyymmdd.xlsx',
                    conditions: {
                        campaign_start_date: '',
                        campaign_end_date: '',
                        categories: '',
                        cycle: cycleFMT[0],
                        closing_date: null,
                        report_date: null,
                        skip_the_first_week_closing: false,
                        include_it_one_week_before: false
                    }
                }
            ]
        },
        {
            label: i18n.t('document.manual'),
            data: [
                {
                    id: '5',
                    source: 'horizontal_manual',
                    name: '横・【事務局マニュアル】 CP名_yyyymmdd.pptx',
                    client: null,
                    client_type: null,
                    partner: null
                },
                {
                    id: '6',
                    source: 'vertical_manual',
                    name: '縦・【事務局マニュアル】 CP名_yyyymmdd.pptx',
                    client: null,
                    client_type: null,
                    partner: null
                }
            ]
        },
        {
            label: i18n.t('document.greetingCard'),
            data: [
                {
                    id: '7',
                    name: '※POP※【挨拶状】_●●●CP_yyyymmdd.pptx',
                    source: 'pop_greeting_card',
                    client: null
                },
                {
                    id: '8',
                    source: 'flower_greeting_card',
                    name: '※お花※【挨拶状】_●●●CP_yyyymmdd.pptx',
                    client: null
                },
                {
                    id: '9',
                    source: 'luxury_greeting_card',
                    name: '※高級感※【挨拶状】_●●●CP_yyyymmdd.pptx',
                    client: null
                },
                {
                    id: '10',
                    source: 'normal_without_replacement_greeting_card',
                    name: '※差換え無し※【挨拶状】_●●●CP_yyyymmdd.pptx',
                    client: null
                },
                {
                    id: '11',
                    source: 'normal_with_replacement_greeting_card',
                    name: '※差換え有※(賞名orコース)【挨拶状】_●●●CP_yyyymmdd.pptx',
                    client: null
                }
            ]
        },
        {
            label: i18n.t('document.termsAndConditions'),
            data: [
                {
                    id: '47',
                    source: 'line',
                    name: '規約雛形【LINE(CR)ﾚｼ】.docx',
                    client: null,
                    rules: [],
                    rulesCheckTab1: [],
                    rulesCheckTab2: []
                },
                {
                    id: '41',
                    source: 'sns_image',
                    name: '規約雛形【SNS#画像】.docx',
                    client: null,
                    rules: [],
                    rulesCheckTab1: [],
                    rulesCheckTab2: []
                },
                {
                    id: '42',
                    source: 'sns_work_image',
                    name: '規約雛形【SNS#作品画像】.docx',
                    client: null,
                    rules: [],
                    rulesCheckTab1: [],
                    rulesCheckTab2: []
                },
                {
                    id: '43',
                    source: 'twi',
                    name: '規約雛形【Twi#】.docx',
                    client: null,
                    rules: [],
                    rulesCheckTab1: [],
                    rulesCheckTab2: []
                },
                {
                    id: '44',
                    source: 'twirt',
                    name: '規約雛形【TwiRT】.docx',
                    client: null,
                    rules: [],
                    rulesCheckTab1: [],
                    rulesCheckTab2: []
                },
                {
                    id: '45',
                    source: 'web',
                    name: '規約雛形【WEBﾚｼ】.docx',
                    client: null,
                    rules: [],
                    rulesCheckTab1: [],
                    rulesCheckTab2: []
                },
                {
                    id: '46',
                    source: 'postcard',
                    name: '規約雛形【ハガキ】.docx',
                    client: null,
                    rules: [],
                    rulesCheckTab1: [],
                    rulesCheckTab2: []
                }
            ]
        },
        {
            label: i18n.t('document.schedule'),
            data: [
                {
                    id: '13',
                    source: 'schedule',
                    name: '※社内用※【CPスケジュールタスク管理シート】'
                }
            ]
        },
        {
            label: i18n.t('document.winningDistributionText'),
            data: []
        },

        {
            label: i18n.t('document.partnerRequestSheet'),
            isCheckbox: true,
            data: [{ id: '19', name: '事務局依頼シート', path: '/require' }]
        },
        {
            label: i18n.t('document.faq'),
            data: [
                {
                    id: '20',
                    source: 'faq',
                    name: '雛形CL※【FAQ】CP名_yyyymmdd.xlsx',
                    preview: true
                }
                // {
                //   id: "21",
                //   name: "雛形OP※【FAQ】CP名_yyyymmdd.xlsx",
                //   preview: true,
                // },
            ]
        }
    ];
}

function LISTNotCondition() {
    return [
        {
            label: 'エスカレーション',
            data: [
                {
                    id: '1',
                    source: 'escalation',
                    name: '【エスカレーション】 ●●●キャンペーン_yyyymmdd.xlsx'
                },
                {
                    id: '2',
                    source: 'escalation_for_partner',
                    name: '※パートナー用※【エスカレーション】 ●●●キャンペーン_yyyymmdd.xlsx'
                },
                {
                    id: '3',
                    source: 'escalation_for_internal',
                    name: '※社内用※【エスカレーション】 ●●●キャンペーン_yyyymmdd.xlsx'
                }
            ]
        },
        {
            label: 'PDCCA アポ資料',
            data: [
                {
                    id: '4',
                    source: 'pdca',
                    name: 'PDCAアポ提案資料.pptx'
                }
            ]
        },
        {
            label: 'お問い合わせFMT',
            data: [
                {
                    id: '26',
                    source: 'fmt_entire_company',
                    name: '※代理店用※【問い合せ報告】●●●キャンペーン__yyyymmdd　祝日一覧無し.xlsx'
                },
                {
                    id: '27',
                    source: 'fmt_agency',
                    name: '※社内全体用※【問い合せ報告】 ●●●キャンペーン__yyyymmdd　祝日一覧無し.xlsx'
                }
            ]
        }
    ];
}

function replaceQ(data, detailBeginForDocument) {
    let context_data = detailBeginForDocument;
    if (context_data.name) data = data.replace(/《キャンペーン名》/g, context_data.name);
    if (context_data.group_total) data = data.replace(/《グループの合計》/g, context_data.group_total);
    if (context_data.include_group_target_product) data = data.replace(/《グループの対象商品含む》/g, context_data.include_group_target_product);
    if (context_data.group_target_product) data = data.replace(/《グループの対象商品》/g, context_data.group_target_product);
    if (context_data.instagram_name) data = data.replace(/《公式Instagramアカウント名》/g, context_data.instagram_name);
    if (context_data.year_end_schedule_formatted) data = data.replace(/《年末年始休み》/g, context_data.year_end_schedule_formatted);
    if (context_data.url) data = data.replace(/《キャンペーンサイトURL》/g, context_data.url);
    if (context_data.hashtag_formatted) data = data.replace(/《CP指定ハッシュタグ》/g, context_data.hashtag_formatted);
    if (context_data.categories_formatted) data = data.replace(/《応募種別》/g, context_data.categories_formatted);
    if (context_data.clients_formatted) data = data.replace(/《クライアント名》/g, context_data.clients_formatted);
    if (context_data.campaign_start_date)
        data = data.replace(/《キャンペーン開始》/g, moment(context_data.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）'));
    if (context_data.campaign_end_date)
        data = data.replace(/《キャンペーン終了》/g, moment(context_data.campaign_end_date).locale('ja').format('YYYY年MM月DD日（ddd）'));
    if (context_data.tel) data = data.replace(/《キャンペーン事務局TEL》/g, context_data.tel);
    if (context_data.email) data = data.replace(/《キャンペーン事務局Mailアドレス》/g, context_data.email);
    if (context_data.secretariat_start_date)
        data = data.replace(/《事務局開始》/g, formatDateCommon(context_data.secretariat_start_date, 'dateTimeJP'));
    if (context_data.secretariat_end_date) data = data.replace(/《事務局終了》/g, formatDateCommon(context_data.secretariat_end_date, 'dateTimeJP'));
    if (context_data.year_end_schedule_from || context_data.year_end_schedule_to)
        data = data.replace(
            /《年末年始休み》/g,
            `(${moment(context_data.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                context_data.year_end_schedule_to
            )
                .locale('ja')
                .format('YYYY年MM月DD日（ddd）')})`
        );
    return data;
}

function replaceA(array, detailBeginForDocument) {
    let context_data = detailBeginForDocument;
    array.forEach((dat, k) => {
        if (context_data.name) array[k].answer = array[k].answer.replace(/《キャンペーン名》/g, context_data.name);
        if (context_data.group_total) array[k].answer = array[k].answer.replace(/《グループの合計》/g, context_data.group_total);
        if (context_data.include_group_target_product)
            array[k].answer = array[k].answer.replace(/《グループの対象商品含む》/g, context_data.include_group_target_product);
        if (context_data.group_target_product)
            array[k].answer = array[k].answer.replace(/《グループの対象商品》/g, context_data.group_target_product);
        if (context_data.instagram_name) array[k].answer = array[k].answer.replace(/《公式Instagramアカウント名》/g, context_data.instagram_name);
        if (context_data.year_end_schedule_formatted)
            array[k].answer = array[k].answer.replace(/《年末年始休み》/g, context_data.year_end_schedule_formatted);
        if (context_data.url) array[k].answer = array[k].answer.replace(/《キャンペーンサイトURL》/g, context_data.url);
        if (context_data.hashtag_formatted) array[k].answer = array[k].answer.replace(/《CP指定ハッシュタグ》/g, context_data.hashtag_formatted);
        if (context_data.categories_formatted) array[k].answer = array[k].answer.replace(/《応募種別》/g, context_data.categories_formatted);
        if (context_data.clients_formatted) array[k].answer = array[k].answer.replace(/《クライアント名》/g, context_data.clients_formatted);
        if (context_data.campaign_start_date)
            array[k].answer = array[k].answer.replace(
                /《キャンペーン開始》/g,
                moment(context_data.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
            );
        if (context_data.campaign_end_date)
            array[k].answer = array[k].answer.replace(
                /《キャンペーン終了》/g,
                moment(context_data.campaign_end_date).locale('ja').format('YYYY年MM月DD日（ddd）')
            );
        if (context_data.tel) array[k].answer = array[k].answer.replace(/《キャンペーン事務局TEL》/g, context_data.tel);
        if (context_data.email) array[k].answer = array[k].answer.replace(/《キャンペーン事務局Mailアドレス》/g, context_data.email);
        if (context_data.secretariat_start_date)
            array[k].answer = array[k].answer.replace(/《事務局開始》/g, formatDateCommon(context_data.secretariat_start_date, 'dateTimeJP'));
        if (context_data.secretariat_end_date)
            array[k].answer = array[k].answer.replace(/《事務局終了》/g, formatDateCommon(context_data.secretariat_end_date, 'dateTimeJP'));
        if (context_data.year_end_schedule_from || context_data.year_end_schedule_to)
            array[k].answer = array[k].answer.replace(
                /《年末年始休み》/g,
                `(${moment(context_data.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                    context_data.year_end_schedule_to
                )
                    .locale('ja')
                    .format('YYYY年MM月DD日（ddd）')})`
            );
    });
    return array;
}

function handMapDataDocument(listCondition, listNotCondition, conditions) {
    let check = true;
    let documents = [];
    listCondition.forEach((item) => {
        item.data.forEach((i) => {
            if (conditions[i.id]) {
                switch (item.label) {
                    case i18n.t('document.termsAndConditions'):
                        if (!i.rules.length) check = false;
                        documents.push({
                            source: i.source,
                            data: {
                                client_id: i?.client?.client_id ?? '',
                                rules: i.rules
                            }
                        });
                        break;
                    case i18n.t('document.schedule'):
                        if (!i?.condition) {
                            check = false;
                            return;
                        }

                        delete i.condition.parentID;
                        delete i.condition.categories;
                        documents.push({
                            source: i.source,
                            data: i.condition
                        });
                        break;

                    case 'FAQ':
                        documents.push({
                            source: i.source,
                            data: {}
                        });
                        break;
                    case i18n.t('document.manual'):
                        if (!i.client || !i.partner || !i.client_type) {
                            check = false;
                        }
                        documents.push({
                            source: i.source,
                            data: {
                                client_id: i.client?.client_id ?? '',
                                client_type_agency_id: i.client_type?.client_type_agency_id ?? '',
                                partner_shipping_id: i.partner?.id ?? ''
                            }
                        });
                        break;
                    case i18n.t('document.mumberOfMessagesFMT'):
                        if (!i?.conditions?.campaign_start_date || !i?.conditions?.campaign_end_date) {
                            check = false;
                        }
                        documents.push({
                            source: i.source,
                            data: []
                        });
                        break;
                    default:
                        if (!i.client && funcConditionLabel(item.label)) {
                            check = false;
                        }
                        documents.push({
                            source: i.source,
                            data: {
                                client_id: i?.client?.client_id ?? ''
                            }
                        });
                }
            }
        });
    });

    listNotCondition.forEach((item) => {
        item.data.forEach((i) => {
            if (conditions[i.id]) {
                documents.push({
                    source: i.source,
                    data: {}
                });
            }
        });
    });

    return {
        check,
        documents
    };
}

function funcConditionLabel(label) {
    return label !== i18n.t('document.winningDistributionText') && label !== i18n.t('document.faq') && label !== i18n.t('document.schedule');
}

function handleDataAllRule(
    data,
    listConditionRuleDetail,
    listDataMasterRuleGeneral,
    listDataMasterRuleGeneralCheck,
    listDataMasterRuleClose,
    listDataMasterRuleCloseCheck,
    dataRuleTab2,
    dataRuleTab2Check
) {
    listConditionRuleDetail = data.data;
    data.data.forEach((item) => {
        if (item.type == 1) {
            item.rules = [];
            listDataMasterRuleGeneral.push(item);
            listDataMasterRuleGeneralCheck.platform.push(item);
        } else if (item.type == 2) {
            item.rules = [];
            listDataMasterRuleClose.push(item);
            listDataMasterRuleCloseCheck.platform.push(item);
        } else {
            item.rules = [];
            if (item.platforms.length > 0) {
                item.platforms.forEach((pl) => {
                    if (!Array.isArray(dataRuleTab2[pl.id])) {
                        dataRuleTab2[pl.id] = [];
                    }
                    dataRuleTab2[pl.id].push(item);
                });
            }
            dataRuleTab2Check = JSON.parse(JSON.stringify(dataRuleTab2));
        }
    });

    return {
        listConditionRuleDetail,
        listDataMasterRuleGeneral,
        listDataMasterRuleGeneralCheck,
        listDataMasterRuleClose,
        listDataMasterRuleCloseCheck,
        dataRuleTab2,
        dataRuleTab2Check
    };
}

export {
    configs,
    DayOfWeek,
    cycleFMT,
    LISTNotCondition,
    LISTCondition,
    replaceQ,
    replaceA,
    handMapDataDocument,
    funcConditionLabel,
    handleDataAllRule
};
