<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Pagination from '@/components/Pagination/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import DatePicker from 'vue2-datepicker';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

import { checkPermission, listPaging, masterMethods } from '@/state/helpers';
import { formatDateCommon } from '@/utils/format';

export default {
    page: {
        title: '管理ログ',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Pagination,
        Multiselect,
        Layout,
        LoadingIcon,
        DatePicker,
        Footer
    },
    data() {
        return {
            listData: [],
            total: 0,
            limit: 10,
            page: 1,
            filters: {
                form_slug: '',
                action_slug: ''
            },
            perPageList: listPaging,
            date_to: {
                created_at: '',
                created_at_y: '',
                created_at_m: '',
                created_at_d: ''
            },
            date_from: {
                created_at: '',
                created_at_y: '',
                created_at_m: '',
                created_at_d: ''
            },
            dataFromSlug: [
                'campaign',
                'client_pic',
                'client',
                'employee',
                'faq',
                'master',
                'partner_pic',
                'partner',
                'prize',
                'return_prize',
                'permission',
                'master_holiday',
                'master_year_end_schedule'
            ],
            sort: {
                id: 'desc'
            },
            dataActionSlug: ['register', 'edit', 'delete', 'copy', 'lock', 'unlock', 'approve'],
            customFormatDate: 'YYYY-MM-DD',
            isLoading: false
        };
    },
    watch: {
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        },
        'date_from.created_at': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_from.created_at_y = date[0];
                this.date_from.created_at_m = date[1];
                this.date_from.created_at_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        },
        'date_to.created_at': function (newName) {
            // Hành động mà bạn muốn thực hiện khi trường 'name' thay đổi
            if (newName) {
                const date = newName.split('-');
                this.date_to.created_at_y = date[0];
                this.date_to.created_at_m = date[1];
                this.date_to.created_at_d = date[2];
            }
            // Thực hiện thao tác khác tại đây
        }
    },
    mounted() {
        this.getList();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        ...masterMethods,
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`];

            if (this.filters) {
                for (const [key, value] of Object.entries(this.filters)) {
                    if (key && value) {
                        query.push(`filters[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            }
            if (this.sort) {
                for (const [key, value] of Object.entries(this.sort)) {
                    if (key && value) {
                        query.push(`sorts[${key}]=${encodeURIComponent(value)}`);
                    }
                }
            }
            if (this.date_from) {
                let date_from = Object.assign({}, this.date_from);
                if (date_from.created_at_y || date_from.created_at_m || date_from.created_at_d)
                    date_from.created_at = date_from.created_at_y + '-' + date_from.created_at_m + '-' + date_from.created_at_d;
                for (const [key, value] of Object.entries(date_from)) {
                    if (key && value) {
                        query.push(`from[${key}]=${value}`);
                    }
                }
            }
            if (this.date_to) {
                let date_to = Object.assign({}, this.date_to);
                if (date_to.created_at_y || date_to.created_at_m || date_to.created_at_d)
                    date_to.created_at = date_to.created_at_y + '-' + date_to.created_at_m + '-' + date_to.created_at_d;
                for (const [key, value] of Object.entries(date_to)) {
                    if (key && value) {
                        query.push(`to[${key}]=${value}`);
                    }
                }
            }
            return query.join('&');
        },
        async getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            try {
                const response = await this.histories(query);
                this.listData = response.data;
                this.total = response._metadata.total;
                this.limit = response._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error fetching posts:', error);
            }
        },

        onChange() {
            this.page = 1;
        },
        search() {
            this.getList();
        },
        clear() {
            this.filters = {
                form_slug: '',
                action_slug: ''
            };
            (this.date_to = {
                created_at_y: '',
                created_at_d: '',
                created_at_m: '',
                created_at: ''
            }),
                (this.date_from = {
                    created_at_y: '',
                    created_at_m: '',
                    created_at_d: '',
                    created_at: ''
                });
            this.getList();
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },
        nameWithLang(data) {
            return this.$t(`screen.` + data);
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">{{ $t('history.title') }}</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('history.dateTime') }}</label>
                                        <div class="w-100">
                                            <div class="d-flex align-items-center mb-2">
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.created_at_y"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    {{ $t('history.year') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.created_at_m"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.month') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_from.created_at_d"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.day') }}
                                                </div>
                                                <div class="d-flex align-items-center" style="min-width: 200px">
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        {{ $t('history.calendar') }}
                                                        <date-picker
                                                            v-model="date_from.created_at"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    {{ $t('history.from') }}
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center mb-2">
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.created_at_y"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mr-2"
                                                    />
                                                    {{ $t('history.year') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.created_at_m"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.month') }}
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        v-model="date_to.created_at_d"
                                                        style="width: 100px; padding-right: 0 !important"
                                                        class="form-control mx-2"
                                                    />
                                                    {{ $t('history.day') }}
                                                </div>
                                                <div class="d-flex align-items-center" style="min-width: 200px">
                                                    <button type="button" class="btn-sm btn btn-info mx-2 box-date">
                                                        {{ $t('history.calendar') }}
                                                        <date-picker
                                                            v-model="date_to.created_at"
                                                            :value-type="customFormatDate"
                                                            :first-day-of-week="1"
                                                            lang="ja"
                                                        ></date-picker>
                                                    </button>
                                                    {{ $t('history.to') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('history.screen') }}</label>
                                        <div class="w-100">
                                            <Multiselect
                                                :id="`select-filters-form_slug`"
                                                :value.sync="filters.form_slug"
                                                :options="dataFromSlug"
                                                :customLabel="`screen`"
                                                :config="{
                                                    trackBy: '',
                                                    label: '',
                                                    error: false
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-2 mw--form" for="validationCustom05">{{ $t('history.action') }}</label>
                                        <div class="w-100">
                                            <Multiselect
                                                :id="`select-filters-action_slug`"
                                                :value.sync="filters.action_slug"
                                                :options="dataActionSlug"
                                                :customLabel="`screen`"
                                                :config="{
                                                    trackBy: '',
                                                    label: '',
                                                    error: false
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">
                                            {{ $t('btn.clear') }}
                                        </button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">
                                            {{ $t('btn.search') }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">
                            管理ログ検索結果 <span>件数（{{ total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>{{ $t('history.logID') }}</th>
                                        <th>{{ $t('history.date') }}</th>
                                        <th>{{ $t('history.email') }}</th>
                                        <th>
                                            {{ $t('history.nameLogin') }}
                                            <span
                                                class="sort"
                                                :class="sort.id"
                                                @click="
                                                    sort.id == 'asc' ? (sort.id = 'desc') : (sort.id = 'asc');
                                                    getList();
                                                "
                                            >
                                                <i class="fas fa-sort-up"></i>
                                                <i class="fas fa-sort-down"></i>
                                            </span>
                                        </th>
                                        <th>{{ $t('history.screen') }}</th>
                                        <th>{{ $t('history.action') }}</th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template>
                                        <tr v-for="(item, k) in listData" :key="item.id">
                                            <td>{{ parseInt((page - 1) * limit) + parseInt(k + 1) }}</td>
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.user ? item.user.name : '' }}</td>
                                            <td>{{ item.user ? item.user.email : '' }}</td>
                                            <td>{{ formatDateCommon(item.created_at, 'dateTimeJP') ?? '' }}</td>
                                            <td>{{ $t(`screen.${item.form_slug}`) }}</td>
                                            <td>{{ $t(`history.${item.action_slug}`) }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
