<template>
    <div>
        <ModalCommon :id="`configModalCommon-${id}`" :config="config" @save="handleConditionFMT()" @close="handleClear()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.secretariatStartDate') }}</label>
                    <div class="col-sm-8">
                        {{ formatDateCommon(dataForm?.campaign_start_date, 'dateTimeJP') ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.campaignEndDate') }}</label>
                    <div class="col-sm-8">
                        {{ formatDateCommon(dataForm?.campaign_end_date, 'dateTimeJP') ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.secretariatType') }}</label>
                    <div class="col-sm-8">
                        {{ joinValue(dataForm?.categories) ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.deliveryCycle') }}</label>
                    <div class="col-sm-8">
                        <Multiselect :id="`select-cycle`" :value.sync="dataForm.cycle" :options="dataCycle" :config.sync="configFieldCycle" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.closingDate') }} <span class="text-danger">*</span></label>
                    <div class="col-sm-8">
                        <Multiselect
                            :id="`select-closing-date`"
                            :value.sync="dataForm.closing_date"
                            :options="dataDayOfWeek"
                            :config.sync="configFieldClosingDate"
                            @remove="handleValidateField(configFieldClosingDate)"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.reportDate') }} <span class="text-danger">*</span></label>
                    <div class="col-sm-8">
                        <Multiselect
                            :id="`select-report-date`"
                            :value.sync="dataForm.report_date"
                            :options="dataDayOfWeek"
                            :config.sync="configFieldReportDate"
                            @remove="handleValidateField(configFieldReportDate)"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.skipTheFirstWeekClosing') }}</label>
                    <div class="col-sm-8">
                        <InputCheckBox
                            :model.sync="dataForm.skip_the_first_week_closing"
                            :id="`check-skip_the_first_week_closing`"
                            :value="dataForm.skip_the_first_week_closing"
                            :label="``"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.includeItOneWeekBefore') }}</label>
                    <div class="col-sm-8">
                        <InputCheckBox
                            :model.sync="dataForm.include_it_one_week_before"
                            :id="`check-include_it_one_week_before`"
                            :value="dataForm.include_it_one_week_before"
                            :label="``"
                        />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import Multiselect from '@/components/Multiselect/main.vue';
import ModalCommon from '@/components/Modal/main.vue';

import { cycleFMT, DayOfWeek } from '@/views/pages/documents/main.js';
import { formatDateCommon } from '@/utils/format';
import { InputCheckBox } from '@/components/Input';
import { configs } from '../../main';
import { masterMethods } from '@/state/helpers';
import { validateField, validateAllField } from '@/utils/validate';
import { showMessage } from '@/utils/messages';

export default {
    name: 'ModalCondition',
    components: {
        Multiselect,
        ModalCommon,
        InputCheckBox
    },
    props: {
        id: {
            required: true
        },
        dataCondition: {
            type: Object,
            default: () => null
        },
        config: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            dataForm: null,
            dataFormClone: JSON.parse(JSON.stringify(this.dataCondition)),
            dataCycle: cycleFMT,
            dataDayOfWeek: DayOfWeek,
            configFieldCycle: configs.configFieldCycle,
            configFieldClosingDate: configs.configFieldClosingDate,
            configFieldReportDate: configs.configFieldReportDate,
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,

        handleValidateField(configField) {
            validateField(configField);
        },

        async handleConditionFMT() {
            let check = validateAllField({ configFieldClosingDate: this.configFieldClosingDate, configFieldReportDate: this.configFieldReportDate });
            if (!check || this.isLoading) return;
            this.isLoading = true;
            try {
                let payload = {
                    ...(this.dataForm.id_fmt_parameter_setting !== null && {
                        id: this.dataForm.id_fmt_parameter_setting
                    }),
                    source: this.config.source.source,
                    campaign_id: this.config?.detailBeginForDocument?.id ?? null,
                    delivery_cycle: this.dataForm.cycle?.id ?? null,
                    report_day: this.dataForm.report_date?.id ?? null,
                    closing_day: this.dataForm.closing_date?.id ?? null,
                    is_skip_the_first_week: this.dataForm?.skip_the_first_week_closing ?? false,
                    is_merged_with_the_last_week: this.dataForm?.include_it_one_week_before ?? false
                };
                await this.postDocumentFMT(payload);
                this.$emit('refresh');
            } catch (error) {
                console.log('error', error);
                showMessage(error.code, this.$bvToast, this.$t(error.code));
            } finally {
                this.$emit('update:dataCondition', this.dataForm);
                this.config.model = false;
                this.isLoading = false;
            }
        },

        handleClear() {
            this.dataForm = this.dataFormClone;
            this.configFieldClosingDate.error = false;
            this.configFieldReportDate.error = false;

            this.$emit('update:dataCondition', this.dataForm);
            this.config.model = false;
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        joinValue(param) {
            if (!param || !param?.length) return '';
            return param.map((o) => o?.category?.value ?? '').join('、');
        },

        handleMapData(newdataCondition) {
            const foundSource = this.config.detailBeginForDocument.fmt_parameter_settings?.find(
                (o) => o.document_template.source == this.config.source.source
            );

            this.dataForm = {
                ...newdataCondition,
                campaign_start_date: this.config.detailBeginForDocument.campaign_start_date,
                campaign_end_date: this.config.detailBeginForDocument.campaign_end_date,
                categories: this.config.detailBeginForDocument.categories,
                cycle: cycleFMT.find((o) => o.id == foundSource?.delivery_cycle) ?? cycleFMT[0],
                report_date: DayOfWeek.find((o) => o.id == foundSource?.report_day) ?? null,
                closing_date: DayOfWeek.find((o) => o.id == foundSource?.closing_day) ?? null,
                skip_the_first_week_closing: foundSource?.is_skip_the_first_week,
                include_it_one_week_before: foundSource?.is_merged_with_the_last_week,
                id_fmt_parameter_setting: foundSource?.id ?? null
            };
            return this.dataForm;
        }
    },
    watch: {
        dataCondition: {
            handler: function (newdataCondition) {
                this.dataForm = this.handleMapData(newdataCondition);
            },
            immediate: true,
            deep: true
        },

        'config.model': {
            handler: function (valShow) {
                if (valShow) {
                    this.dataFormClone = JSON.parse(JSON.stringify(this.dataCondition));
                    this.dataForm = this.handleMapData(this.dataCondition);
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
